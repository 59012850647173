import React, { ReactNode } from "react";
import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

const StyledLink = styled(Link)`
  padding: 20px;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font: ${({ theme }) => theme.fonts.headings.bold};
  text-transform: uppercase;
  font-size: 110%;
  letter-spacing: 0.1em;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
const LinkBtn: React.FC<Omit<GatsbyLinkProps<{}>, "ref">> = props => (
  <StyledLink {...props} />
);

export default LinkBtn;
